<template lang="pug">
nav.navigation-v2
  ul.unstyled-list
    li(v-for="(item, itemIndex) in items")
      hr.horizontal-divider(v-if="item.key === 'divider'")
      template(v-else-if="item.key === 'home'")
        om-button(
          ghost
          block
          :class="{ 'is-active': isHomeActive }"
          :icon="getIcon(item)"
          @click="$emit('click', `${itemIndex}`)"
        ) {{ $t(item.name) }}
      template(v-else)
        router-link(
          tag="div"
          v-if="item.to"
          :to="item.to"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
        )
          om-button(
            ghost
            block
            :class="{ 'is-active': isFirstLevelActive(item.to), 'no-icon': item.iconless }"
            :icon="getIcon(item, isActive)"
            @click="emitAndGo(navigate, `${itemIndex}`, $event)"
          ) {{ $t(item.name) }}
          ul.unstyled-list.submenu(v-if="item.children && (isActive || hasActiveChild(item))")
            li(v-for="(subItem, subItemIndex) in item.children")
              router-link(
                v-if="subItem.to"
                :to="subItem.to"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
              )
                om-button(
                  ghost
                  block
                  :class="{ 'navigation-v2-btn--max-width': true, 'is-active': isActive, 'no-icon': subItem.iconless }"
                  @click="emitAndGo(navigate, `${itemIndex}.${subItemIndex}`, $event)"
                ) {{ $t(subItem.name) }}
              om-button(
                v-else
                ghost
                block
                :class="{ 'no-icon': subItem.iconless }"
                :icon="subItem.icon"
                @click="emitAndGo(navigate, `${itemIndex}.${subItemIndex}`, $event)"
              ) {{ subItem.name }}
        .from-scratch-button(v-else-if="item.key === 'new-empty'")
          om-button(ghost block :icon="getIcon(item)" @click="$emit('click', item.key)") {{ item.name }}
        om-button(v-else ghost block :icon="getIcon(item)" @click="$emit('click', item.key)") {{ item.name }}
</template>

<script>
  import cookie from '@/mixins/cookie';

  export default {
    mixins: [cookie],
    props: {
      items: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      isHomeActive() {
        return this.$route.name === 'templates';
      },
      isBanners() {
        return ['themes-collection', 'seasonal-collection'].includes(this.$route.name);
      },
    },
    methods: {
      getIcon({ icon, iconless }) {
        if (iconless) return;
        return icon;
      },
      emitAndGo(navigate, key, event) {
        this.$emit('click', key);
        navigate(event);
      },
      isFirstLevelActive(route) {
        if (!route) return false;
        const isActiveUseCasePath =
          route.name?.includes('use-case') && this.$route.name?.includes('use-case');
        return isActiveUseCasePath || (this.$route.name === route.name && !this.isBanners);
      },
      isSecondLevelActive(route) {
        if (!route) return false;
        return this.$route.name === route.name && !this.$route.params?.slug;
      },
      hasActiveChild(item) {
        if (!item.children) return false;

        return item.children.some((child) => {
          if (!child.to) return false;

          const childRoute = this.$router.resolve(child.to).route;
          return this.$route.matched.some((record) => {
            return record.name === childRoute.name;
          });
        });
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .navigation-v2
    .unstyled-list
      padding: 0
      margin: 0
      li
        list-style: none
      li + li
        margin-top: .5rem
      .from-scratch-button
        display: flex
        align-items: center
    &-btn--max-width
      span
        display: block !important
        max-width: 11.625rem
        text-overflow: ellipsis
        overflow: hidden

    .btn.btn-link
      justify-content: flex-start
      &.no-icon
        padding-left: 2.375rem
      &:hover,
      &.is-active
        color: $om-orange-500
        background: $om-orange-500-10
    .submenu
      li
        margin-top: .5rem
      .btn.btn-link
        padding-left: 2.375rem
        &.no-icon
          padding-left: 4.375rem
</style>
